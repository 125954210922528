<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="isFeedbackOpen">
      <Dialog
        as="div"
        static
        class="fixed z-10 inset-0 overflow-y-auto"
        @close="isFeedbackOpen = false"
        :open="isFeedbackOpen"
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              @submit="handleSubmit"
              class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                px-4
                pt-5
                pb-4
                text-left
                overflow-hidden
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
              "
            >
              <div class="flex-1 flex flex-col">
                <div class="flex items-start justify-between">
                  <h2 class="text-lg font-medium text-gray-900">
                    Help us improve our app
                  </h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button
                      type="button"
                      class="
                        bg-white
                        rounded-md
                        text-gray-400
                        hover:text-gray-500
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                      "
                      @click="isFeedbackOpen = false"
                    >
                      <span class="sr-only">Close panel</span>
                      <svg
                        class="h-6 w-6"
                        x-description="Heroicon name: outline/x"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="my-6 relative flex-1">
                  <div>
                    <textarea
                      v-model="message"
                      required
                      autofocus
                      rows="6"
                      class="
                        shadow-sm
                        focus:ring-blue-500 focus:border-blue-500
                        block
                        w-full
                        sm:text-sm
                        border border-gray-300
                        rounded-md
                      "
                    ></textarea>
                  </div>
                  <p class="mt-2 text-sm text-gray-500">
                    Write a few sentences about your experience
                  </p>
                </div>
              </div>
              <div class="flex-shrink-0 px-4 flex justify-end">
                <button
                  type="button"
                  class="
                    bg-white
                    py-2
                    px-4
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-blue-500
                  "
                  @click="isFeedbackOpen = false"
                >
                  Cancel
                </button>
                <button
                  :disabled="isLoading"
                  type="submit"
                  :class="[
                    ` ml-4
                    inline-flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    shadow-sm
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-blue-600
                    hover:bg-blue-700
                    focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                    isLoading && 'cursor-not-allowed opacity-60',
                  ]"
                >
                  <svg
                    v-if="isLoading"
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Send feedback
                </button>
              </div>
            </form>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              pt-5
              pb-4
              bg-light-blue-900
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <img
                class="w-auto"
                src="@/assets/logo-white-trans.png"
                alt="Logo"
              />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="flex-1 px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  class="block"
                  :to="item.href"
                  v-slot="{ isActive }"
                >
                  <span
                    v-if="item.role === user.role.name"
                    :class="[
                      isActive
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-blue-100 hover:bg-light-blue-600',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        isActive ? 'text-gray-900' : 'text-blue-100',
                        'mr-3 flex-shrink-0 h-6 w-6 ',
                      ]"
                      aria-hidden="true"
                    />
                    <div class="inline-flex justify-between w-full">
                      {{ item.name }}
                      <span
                        v-if="item.actionCounts"
                        class="
                          inline-flex
                          items-center
                          px-2.5
                          py-0.5
                          rounded-full
                          text-xs
                          font-medium
                        "
                        :class="
                          isActive
                            ? 'text-white bg-primary '
                            : 'bg-white text-gray-800'
                        "
                      >
                        {{ actionsCount }}
                      </span>
                    </div>
                  </span>
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-light-blue-900 md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <router-link :to="baseNavigation">
            <div class="flex items-center flex-shrink-0 px-4">
              <img
                class="w-auto"
                src="@/assets/logo-white-trans.png"
                alt="Workflow"
              />
            </div>
          </router-link>
          <div class="mt-5 flex-1 flex flex-col">
            <nav class="flex-1 px-2 space-y-1">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                class="block"
                :to="item.href"
                v-slot="{ isActive }"
              >
                <span
                  v-if="item.role === user.role.name"
                  :class="[
                    isActive
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-blue-100 hover:bg-light-blue-600',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      isActive ? 'text-gray-900' : 'text-blue-100',
                      'mr-3 flex-shrink-0 h-6 w-6 ',
                    ]"
                    aria-hidden="true"
                  />
                  <div class="inline-flex justify-between w-full">
                    {{ item.name }}
                    <span
                      v-if="item.actionCounts"
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-0.5
                        rounded-full
                        text-xs
                        font-medium
                      "
                      :class="
                        isActive
                          ? 'text-white bg-primary '
                          : 'bg-white text-gray-800'
                      "
                    >
                      {{ actionsCount }}
                    </span>
                  </div>
                </span>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          class="
            px-4
            border-r border-gray-200
            text-gray-500
            focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500
            md:hidden
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="bg-light-blue-900 flex-1 px-4 flex justify-between">
          <div class="flex-1 flex"></div>
          <div class="ml-4 flex items-center md:ml-6">
            <button
              @click="isFeedbackOpen = true"
              type="button"
              class="
                inline-flex
                mr-5
                items-center
                px-4
                py-2
                border border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                focus:outline-none focus:ring-2 focus:ring-blue-500
              "
            >
              <SpeakerphoneIcon class="mr-3 h-5 w-5" aria-hidden="true" />
              Feedback?
            </button>
            <button
              class="
                p-1
                rounded-full
                text-white
                focus:outline-none focus:ring-1 focus:ring-blue-500
              "
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="
                    max-w-xs
                    flex
                    items-center
                    text-sm
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-blue-500
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <Avatar
                    :user="user"
                    :className="['h-8', 'w-8', 'rounded-full']"
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-56
                    rounded-md
                    shadow-lg
                    py-1
                    z-10
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <div class="px-4 py-2 text-sm text-gray-700 flex flex-col">
                    <p class="text-sm">
                      {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="text-sm font-medium">
                      {{ user.email }}
                    </p>
                  </div>
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link
                      v-if="item.route === 'settings-general'"
                      :to="{ name: item.route }"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'px-4 py-2 text-sm text-gray-700 flex items-center',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        :class="['mr-3 flex-shrink-0 h-6 w-6 ']"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </router-link>
                    <a
                      @click="store.dispatch('user/logOut')"
                      v-else
                      class="
                        px-4
                        py-2
                        text-sm text-gray-700
                        flex
                        items-center
                        cursor-pointer
                        hover:bg-gray-100
                        w-full
                        focus:outline-none
                        focus-within:border-black
                      "
                    >
                      <component
                        :is="item.icon"
                        :class="['mr-3 flex-shrink-0 h-6 w-6 ']"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="h-full">
          <div class="max-w-7xl px-4 sm:px-6 md:px-8 h-full pt-6">
            <slot />
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { getMe } from "../services/auth";
import Avatar from "@/components/Avatar";

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  HomeIcon,
  CursorClickIcon,
  UserGroupIcon,
  XIcon,
  FolderIcon,
  MenuAlt2Icon,
  LoginIcon,
} from "@heroicons/vue/outline";
import {
  SearchIcon,
  BellIcon,
  SpeakerphoneIcon,
  CogIcon,
} from "@heroicons/vue/solid";
import { sendEmail } from "../services/email";
import { useToast } from "vue-toastification";

const navigation = [
  { name: "Updates", href: "/updates", icon: HomeIcon, role: "Client" },
  {
    name: "Actions",
    href: "/actions",
    icon: CursorClickIcon,
    actionCounts: 6,
    role: "Client",
  },
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon, role: "Solicitors" },
  {
    name: "Clients",
    href: "/clients",
    icon: UserGroupIcon,
    role: "Solicitors",
  },
  { name: "Cases", href: "/cases", icon: FolderIcon, role: "Solicitors" },
];
const userNavigation = [
  { name: "Account settings", route: "settings-general", icon: CogIcon },
  { name: "Sign out", route: "", icon: LoginIcon },
];

export default {
  components: {
    Avatar,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CogIcon,
    MenuAlt2Icon,
    LoginIcon,
    SearchIcon,
    XIcon,
    SpeakerphoneIcon,
  },
  setup() {
    const toast = useToast();
    const sidebarOpen = ref(false);
    const isFeedbackOpen = ref(false);
    const isLoading = ref(false);
    const message = ref("");
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const baseNavigation = ref([]);
    onBeforeMount(async () => {
      // Todo: just Fetch it when user role equals Client
      store.dispatch("action/getUpdates", 0);

      if (!user.value.name) {
        try {
          const { data } = await getMe();
          store.dispatch("user/setUser", data);
          baseNavigation.value = navigation.find(
            (i) => i.role === data.role.name
          );
        } catch (e) {
          console.error(e);
        }
      } else {
        baseNavigation.value = navigation.find(
          (i) => i.role === user.value.role.name
        );
      }
    });
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        isLoading.value = true;
        await sendEmail({
          subject: "App Feedback",
          to: ["mert@1984.design", "bora@1984.design"],
          text: message.value,
        });

        setTimeout(() => {
          toast.success(
            `Thanks for your time, \nYour feedback improves our App.`
          );
          message.value = "";
          isFeedbackOpen.value = false;
          isLoading.value = false;
        }, 2000);
      } catch (e) {
        setTimeout(() => {
          toast.error(`Something happen`);
          isLoading.value = false;
          message.value = "";
        }, 2000);
      }
    };
    return {
      navigation,
      userNavigation,
      sidebarOpen,
      isFeedbackOpen,
      handleSubmit,
      message,
      user,
      isLoading,
      store,
      Avatar,
      actionsCount: computed(() => store.getters["action/actionsCount"]),
      baseNavigation: baseNavigation.value,
    };
  },
};
</script>
