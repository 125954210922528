import { createWebHistory, createRouter } from 'vue-router';
import { isLoggedIn } from './services/auth';
import { Role } from './utils';

const routes = [
  {
    path: '/updates',
    name: 'updates',
    component: () => import('@/views/dashboard/Updates.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Client],
    },
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/dashboard/Clients/Index.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Solicitor, Role.Admin],
    },
  },
  {
    path: '/clients/:id',
    name: 'client',
    component: () => import('@/views/dashboard/Clients/[id]/Index.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Solicitor, Role.Admin],
    },
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import('@/views/dashboard/Cases/Index.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Solicitor, Role.Admin],
    },
  },
  {
    path: '/cases/:id',
    redirect: { name: 'case-details' },
    name: 'case',
    component: () => import('@/views/dashboard/Cases/[id]/Index.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Solicitor, Role.Admin],
    },
    children: [
      {
        path: 'details',
        name: 'case-details',
        component: () => import('@/views/dashboard/Cases/[id]/Details.vue'),
      },
      {
        path: 'updates',
        name: 'case-updates',
        component: () => import('@/views/dashboard/Cases/[id]/Updates.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Solicitor, Role.Admin],
    },
  },
  {
    path: '/actions',
    name: 'actions',
    component: () => import('@/views/dashboard/Actions/Index.vue'),
    children: [
      {
        path: '',
        name: 'actions-active',
        component: () => import('@/views/dashboard/Actions/Active.vue'),
      },
      {
        path: 'completed',
        name: 'actions-completed',
        component: () => import('@/views/dashboard/Actions/Completed.vue'),
      },
    ],
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Client],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/dashboard/Settings/Index.vue'),
    children: [
      {
        path: '',
        name: 'settings-general',
        component: () => import('@/views/dashboard/Settings/General.vue'),
      },
      {
        path: 'pwd',
        name: 'settings-pwd',
        component: () => import('@/views/dashboard/Settings/Pwd.vue'),
      },
      {
        path: 'notification',
        name: 'settings-notification',
        component: () => import('@/views/dashboard/Settings/Notification.vue'),
      },
      {
        path: 'verification',
        name: 'settings-verification',
        component: () => import('@/views/dashboard/Settings/Verification.vue'),
      },
    ],
    meta: {
      layout: 'AppLayoutDashboard',
      authorize: [Role.Solicitor, Role.Admin, Role.Client],
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/landing/Home.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/landing/Login.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/landing/Register.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import('@/views/landing/onboarding/Index.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
  {
    path: '/success',
    name: 'payment-success',
    component: () => import('@/views/dashboard/Payment/Success.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
  {
    path: '/verifed',
    name: 'id-verifed',
    component: () => import('@/views/dashboard/Verifed.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
  {
    path: '/onboarding/step',
    component: () => import('@/views/landing/onboarding/Step.vue'),
    redirect: { name: 1 },
    children: [
      {
        path: '1',
        name: 1,
        component: () => import('@/views/landing/onboarding/StepOne.vue'),
      },
      {
        path: '2',
        name: 2,
        component: () => import('@/views/landing/onboarding/StepTwo.vue'),
      },
      {
        path: '3',
        name: 3,
        component: () => import('@/views/landing/onboarding/StepThree.vue'),
      },
      {
        path: 'quote',
        name: 'quote',
        component: () => import('@/views/landing/onboarding/Quote.vue'),
      },
    ],
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('@/views/dashboard/Pdf.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      authorize: [],
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  // debugger; // eslint-disable-line no-debugger

  if (authorize.length > 0) {
    if (!isLoggedIn()) {
      // not logged in so redirect to login page with the return url
      return next({ name: 'Login' });
    }
    const R = localStorage.getItem('r');
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(R)) {
      // role not authorised so redirect to home page
      return next({ path: '/' });
    }
  }

  next();
});
export default router;
