export const Role = {
  Client: 'Client',
  Admin: 'Admin',
  Solicitor: 'Solicitors',
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
  }
};
