import axios from './http';

export const getClients = ({ start, limit, search }) =>
  axios.get('users', {
    params: {
      _sort: 'created_at:desc',
      _start: start === 1 || start === 0 ? 0 : (start - 1) * 10,
      'role.name': 'Client',
      _limit: limit,
      _q: search,
    },
  });

export const getClientsCount = (search) =>
  axios.get(`users/count/?role.name=Client`, {
    params: {
      _q: search,
    },
  });

export const getClient = (id) => axios.get(`users/${id}`);
