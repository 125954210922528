import {
  createCaseNote,
  getCase,
  getCases,
  getCasesCount,
  getMountlyCasesCount,
  getMyCases,
} from '../../services/case';

const state = {
  sell_case: {
    amount: '',
    app_type: '',
    is_mortgage: false,
    property_type: '',
    address: {
      street_address: '',
      postcode: '',
      city: '',
      country: '',
      coords: '{ "lat": 52.024182, "lng": -1.0654541 }',
    },
  },
  purchase_case: {
    amount: '',
    app_type: '',
    is_mortgage: false,
    property_type: '',
    address: {
      street_address: '',
      postcode: '',
      city: '',
      country: '',
      coords: '{ "lat": 52.024182, "lng": -1.0654541 }',
    },
  },
  property: '',
  personal: {},
  cases: {
    items: [],
    count: 0,
  },
  dashboard: {
    cases: 0,
    mountlyCases: 0,
  },
  case: {},
  case_notes: [],
};

// getters
const getters = {};

// actions
const actions = {
  updateCase({ commit }, payload) {
    commit('UPDATE_SELLCASE', payload.sell_case);
    commit('UPDATE_PURCHASECASE', payload.purchase_case);
  },
  updateProperty({ commit }, payload) {
    commit('UPDATE_PROPERTY', payload.type);
    commit('UPDATE_PROPERTY_TYPES', payload.types);
  },
  updatePersonal({ commit }, payload) {
    commit('UPDATE_PERSONAL', payload);
  },
  async getCases({ commit, state }, start) {
    const { data } = await getCases(start);
    let count = state.cases.count;
    if (!(count > 0)) {
      const { data: caseCount } = await getCasesCount();
      count = caseCount;
    }
    commit('SET_CASES', { items: data, count });
  },
  async getCase({ commit }, id) {
    const { data } = await getCase(id);
    commit('SET_CASE', data);
  },
  async getMyCases({ commit }) {
    const { data } = await getMyCases();
    commit('SET_CASES', { items: data, count: 0 });
    return data;
  },
  async createCaseNote({ dispatch }, data) {
    await createCaseNote({
      note: data.note,
      case: data.caseId,
    });
    await dispatch('getCase', data.caseId);
  },
  async getDashboardValues({ commit }) {
    const { data: totalCases } = await getCasesCount();
    const { data: totalMountlyCases } = await getMountlyCasesCount();
    commit('SET_DASHBOARD_VALUES', { totalCases, totalMountlyCases });
  },
};

// mutations
const mutations = {
  UPDATE_CASE(state, payload) {
    state.case = payload;
  },
  UPDATE_PROPERTY(state, payload) {
    state.property = payload;
  },
  UPDATE_PROPERTY_TYPES(state, payload) {
    state.sell_case.app_type = payload.find((i) => i.uid === 'sale').id;
    state.purchase_case.app_type = payload.find((i) => i.uid === 'purchase').id;
  },
  UPDATE_SELLCASE(state, payload) {
    state.sell_case = payload;
  },
  UPDATE_PURCHASECASE(state, payload) {
    state.purchase_case = payload;
  },
  UPDATE_PERSONAL(state, payload) {
    state.personal = payload;
  },
  SET_CASES(state, payload) {
    state.cases = payload;
  },
  SET_CASE(state, payload) {
    state.case = payload;
  },
  SET_CASE_NOTES(state, paylad) {
    state.case_notes = paylad;
  },
  SET_DASHBOARD_VALUES(state, payload) {
    state.dashboard = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
