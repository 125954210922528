import { clearAuthToken, registerUser, updateMe } from '../../services/auth';
import { getClient, getClients, getClientsCount } from '../../services/user';

const state = {
  user: {
    role: {
      name: '',
    },
  },
  clients: {
    items: [],
    count: 0,
  },
  client: {},
};

// getters
const getters = {
  user: (state) => {
    return state.user;
  },
};

// actions
const actions = {
  setUser({ commit }, user) {
    localStorage.setItem('r', user.role.name);
    commit('SET_USER', user);
  },
  logOut({ commit }) {
    commit('LOGOUT');
    clearAuthToken();
    window.location.href = '/login';
  },
  async registerUser({ commit }, payload) {
    const resp = await registerUser(payload);
    console.log(resp, commit);
  },
  async getClients({ commit }, payload) {
    const { data } = await getClients({
      start: payload?.start || 0,
      limit: payload?.limit || 10,
      search: payload?.search || '',
    });
    const { data: count } = await getClientsCount(payload?.search);
    commit('SET_CLIENTS', { items: data, count });
  },
  async getClient({ commit }, payload) {
    const { data } = await getClient(payload);
    commit('SET_CLIENT', data);
  },
  async updateMe({ commit }, payload) {
    const { data } = await updateMe(payload);
    console.log(data);
    commit('SET_USER', data);
  },
};

// mutations
const mutations = {
  SET_CLIENTS(state, clients) {
    state.clients = clients;
  },
  SET_CLIENT(state, client) {
    state.client = client;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  LOGOUT(state) {
    state.user = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
