import axios from 'axios';
import authAxios from './http';
import { stringify } from 'qs';
import { DateTime } from 'luxon';

export const createTemporaryCase = (data) =>
  axios.post(`${process.env.VUE_APP_API_URL}/temporary_cases`, data);

export const getPropertyTypes = () =>
  axios.get(`${process.env.VUE_APP_API_URL}/property-types`);

export const getAppTypes = () =>
  axios.get(`${process.env.VUE_APP_API_URL}/app-types`);

export const getCases = (start = 0, limit = 10) =>
  authAxios.get(
    `cases?_sort=created_at:desc&_start=${
      start === 1 || start === 0 ? 0 : (start - 1) * 10
    }&_limit=${limit}`
  );

export const getMountlyCasesCount = () => {
  const query = stringify({
    created_at_gte: DateTime.local()
      .startOf('month')
      .toISODate(),
    created_at_lte: DateTime.local()
      .endOf('month')
      .toISODate(),
  });
  return new authAxios.get(`cases/count?${query}`);
};

export const getCase = (id) => authAxios.get(`cases/${id}`);

export const getCasesCount = () => authAxios.get(`cases/count`);

export const getMyCases = () => authAxios.get(`cases/me`);

export const createCaseNote = (data) =>
  authAxios.post(`case-notes`, {
    note: data.note,
    case: data.case,
  });

export const getStages = () => authAxios.get(`stages`);
