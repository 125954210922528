import axios from './http';
import { stringify } from 'qs';

export const getUpdateTypes = () => axios.get(`update-types`);

export const getSignatureTypes = () => axios.get(`signature-types`);

export const upload = (formData) =>
  axios.post(`upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createUpdate = (data) => axios.post(`actions`, data);

export const getUpdates = (action) =>
  axios.get(`updates/me`, {
    params: {
      isAction: action,
    },
  });

export const getRecentUpdates = (start = 0, limit = 10) => {
  const query = stringify({
    _sort: 'created_at:desc',
    _start: start === 1 || start === 0 ? 0 : (start - 1) * 10,
    _limit: limit,
    _where: { 'update_status.uid': 'completed' },
  });
  return axios.get(`updates?${query}`);
};

export const getRecentUpdateCount = () => {
  const query = stringify({
    _sort: 'created_at:desc',
    _where: { 'update_status.uid': 'completed' },
  });
  return axios.get(`updates/count?${query}`);
};

export const updateAction = (id, data) => axios.put(`updates/${id}`, data);
